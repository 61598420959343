// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E9sjnjSfrcgbUA5eV978 .yNlRpkP3Cdun2_mFjr7m{align-items:center;margin:auto;padding:0 0 60px}.E9sjnjSfrcgbUA5eV978 .xjaPj2_h22qtB_bka7F0{display:flex;justify-content:center;align-items:center;min-height:calc(100vh - 16px)}.E9sjnjSfrcgbUA5eV978 .xjaPj2_h22qtB_bka7F0 div[role=progressbar]{width:50px;height:50px}", "",{"version":3,"sources":["webpack://./src/components/Completion/SoftwareCompletion.module.scss"],"names":[],"mappings":"AACE,4CACE,kBAAA,CACA,WAAA,CACA,gBAAA,CAGF,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,6BAAA,CAEA,kEACE,UAAA,CACA,WAAA","sourcesContent":[".completed {\n  .spinner {\n    align-items: center;\n    margin: auto;\n    padding: 0 0 60px;\n  }\n\n  .progressBar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: calc(100vh - 2 * 8px);\n\n    div[role='progressbar'] {\n      width: 50px;\n      height: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completed": "E9sjnjSfrcgbUA5eV978",
	"spinner": "yNlRpkP3Cdun2_mFjr7m",
	"progressBar": "xjaPj2_h22qtB_bka7F0"
};
export default ___CSS_LOADER_EXPORT___;
